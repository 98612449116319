<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="fs-lg p-4 bg-earth rounded text-white text-center push">
          <div class="mb-2">
            <i class="fa fa-2x fa-gift opacity-50"></i>
          </div>
          <h4 class="mb-1">欢迎来到 {{ config.webName }} !</h4>
          <p class="fs-sm fw-medium text-white mb-0">
            你的{{ config.webName }}，不仅仅是<strong>{{
              config.webName
            }}</strong>
          </p>
        </div>
      </div>
      <div class="col-6 col-xl-3">
        <a
          class="block block-rounded block-link-pop text-end"
          href="javascript:void(0)"
        >
          <div
            class="block-content block-content-full d-sm-flex justify-content-between align-items-center border-black-op-b border-3"
          >
            <div class="d-none d-sm-block">
              <i class="si si-bar-chart fa-2x text-primary-light"></i>
            </div>
            <div class="text-end">
              <div class="fs-3 fw-semibold text-primary">
                {{ config.userCount }}
              </div>
              <div class="fs-sm fw-semibold text-uppercase text-muted">
                用户数量
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-6 col-xl-3">
        <a
          class="block block-rounded block-link-pop text-end"
          href="javascript:void(0)"
        >
          <div
            class="block-content block-content-full d-sm-flex justify-content-between align-items-center border-black-op-b border-3"
          >
            <div class="d-none d-sm-block">
              <i class="si si-trophy fa-2x text-earth-light"></i>
            </div>
            <div class="text-end">
              <div class="fs-3 fw-semibold text-earth">
                {{ config.taskCount }}
              </div>
              <div class="fs-sm fw-semibold text-uppercase text-muted">
                托管账号
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-6 col-xl-3">
        <a
          class="block block-rounded block-link-pop text-end"
          href="javascript:void(0)"
        >
          <div
            class="block-content block-content-full d-sm-flex justify-content-between align-items-center border-black-op-b border-3"
          >
            <div class="d-none d-sm-block">
              <i class="si si-bar-chart fa-2x text-elegance-light"></i>
            </div>
            <div class="text-end">
              <div class="fs-3 fw-semibold text-elegance">
                {{ config.jobCount }}
              </div>
              <div class="fs-sm fw-semibold text-uppercase text-muted">
                添加任务
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-6 col-xl-3">
        <a
          class="block block-rounded block-link-pop text-end"
          href="javascript:void(0)"
        >
          <div
            class="block-content block-content-full d-sm-flex justify-content-between align-items-center border-black-op-b border-3"
          >
            <div class="d-none d-sm-block">
              <i class="si si-fire fa-2x text-corporate-light"></i>
            </div>
            <div class="text-end">
              <div class="fs-3 fw-semibold text-pulse">
                {{ config.dayCount }}
              </div>
              <div class="fs-sm fw-semibold text-uppercase text-muted">
                运行天数
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 mb-2">
        <div class="block block-rounded mb-0">
          <div class="block-header block-header-default">
            <h3 class="block-title">公告通知</h3>
          </div>

          <div class="block-content block-content-full">
            <el-scrollbar max-height="232px">
              <div class="fw-medium fs-sm">
                <div
                  v-for="notice in config.notice"
                  :key="notice.id"
                  class="border-start border-4 rounded-2 border-primary mb-2"
                >
                  <div class="rounded p-2 text-pulse-light">
                    <p class="m-1" v-html="notice.content"></p>
                    <p class="m-2 text-muted">2022-04-30 19:40</p>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <a
          class="block block-rounded block-link-shadow text-center h-100 mb-0"
          href="#"
        >
          <div class="block-content bg-gd-dusk">
            <div class="push">
              <img
                class="img-avatar img-avatar-thumb"
                :src="'//q2.qlogo.cn/g?b=qq&nk=' + user.qq + '&s=100'"
                alt=""
              />
            </div>
            <div class="pull-x pull-b py-2 bg-black-25">
              <div class="fw-semibold mb-1 text-white">
                <i class="fa fa-star text-white-75 ms-1"></i>
                {{ user.nickname }}
                <span class="fs-xs">UUID:{{ user.uid }}</span>
              </div>

              <div class="fs-sm text-white-75">普通用户</div>

              <div class="fs-sm text-white-75">
                共有 {{ user.quota }} 个配额 | 已使用 ： {{ user.quotaUsed }} 个
              </div>
            </div>
          </div>
          <div class="block-content">
            <div class="row items-push text-center">
              <div class="col-6">
                <div class="mb-1"><i class="si si-user fa-2x"></i></div>
                <div class="fs-sm fw-medium text-muted">{{ user.role }}</div>
              </div>
              <div class="col-6">
                <div class="mb-1"><i class="si si-wallet fa-2x"></i></div>
                <div class="fs-sm fw-medium text-muted">
                  {{ user.money }} 元
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getWebInfo } from "@/api/webInfo";
export default {
  name: "Home",
  data() {
    const defaultForm = {
      webName: "",
      userCount: 0,
      taskCount: 0,
      jobCount: 0,
      dayCount: 0,
      notice: [],
    };
    return {
      config: { ...defaultForm },
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
    };
  },
  created() {
    getWebInfo().then((res) => {
      this.config = res;
    });
  },
  methods: {},
};
</script>

import request from "@/utils/request";

/**
 * 获取验证码
 */
export async function getWebInfo() {
  const res = await request.get("/home/config");
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}
